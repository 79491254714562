import React from 'react';
import { ReactComponent as PoweredByStripe } from 'components/assets/icons/powered-by-stripe.svg';
import { Card } from '../../index';
import classNames from 'classnames';

export interface StripePanelFooterProps {
  className?: string;
}

const StripePanelFooter: React.FC<StripePanelFooterProps> = ({ className }) => {
  return (
    <Card className={classNames('flex items-center justify-center', className)}>
      <PoweredByStripe className="w-32 my-1 opacity-30" />
    </Card>
  );
};

export default StripePanelFooter;

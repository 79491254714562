import { useEffect, useMemo, useState } from 'react';
import InvoiceService from 'services/invoice';
import Invoice from 'models/invoice';
import useConfig from 'hooks/useConfig';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppConfig from 'models/app-config';
import { setOpenGraphMetaTitle } from 'helpers/opengraph-helper';
import { PageLoader } from 'components/ui';
import InvoiceViewSwitcher from 'components/ui/invoice/invoice-view-switcher';
import { useThemeContext } from 'context/invoice-theme';

type InvoicePageParams = {
  onlineInvoiceId: string;
};

const InvoicePage = () => {
  const config = useConfig() as AppConfig;
  const { setTheme } = useThemeContext();
  const navigate = useNavigate();
  const { onlineInvoiceId } = useParams<InvoicePageParams>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const invoiceDownload = useMemo(() => {
    const fileName = `invoice-${invoice?.number}.pdf`;
    const url = `${config.bff.url}/OnlineInvoices/${invoice?.id}/pdf?fileName=${fileName}`;
    return { url, fileName };
  }, [invoice]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getInvoice = async (id: string) => {
      const preview = searchParams.get('preview') || '';
      const invoiceService = new InvoiceService(config);
      const invoice = await invoiceService.getInvoice(id, preview);

      setInvoice(invoice);
      setLoading(false);
    };

    getInvoice(onlineInvoiceId as string);
  }, [onlineInvoiceId]);

  useEffect(() => {
    if (loading) {
      return;
    }

    // Api call has finished and invoice was not found
    if (!invoice) {
      navigate('/not-found');
    }
  }, [loading, invoice]);

  useEffect(() => {
    if (invoice) {
      const title = `Xero Go Invoice #${invoice.number}`;
      setOpenGraphMetaTitle(document, title);
      setTheme(invoice.customization);
    }
  }, [invoice]);

  if (loading) {
    return <PageLoader />;
  }

  if (!invoice) {
    return null;
  }

  return (
    <div className="h-full pt-10 md:py-10">
      <InvoiceViewSwitcher
        invoice={invoice}
        downloadUrl={invoiceDownload.url}
        downloadFileName={invoiceDownload.fileName}
      />
    </div>
  );
};

export default InvoicePage;

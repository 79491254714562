import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Customization from 'models/customization';
import Business from 'models/business';

export interface InvoiceHeaderProps {
  business: Business;
  isGstRegistered?: boolean;
  theme: Customization;
  className?: string;
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  business,
  isGstRegistered,
  theme,
  className
}) => {
  return (
    <div className={classNames('grid grid-cols-1 md:grid-cols-2', className)}>
      <div className="order-1">
        {theme.logo && (
          <div className="h-24 w-24 md:h-[9.375rem] md:w-[9.375rem] mb-6 md:mb-0">
            <img className="max-w-full max-h-full" src={theme.logo} />
          </div>
        )}
      </div>

      <div className="text-gray-600 md:text-right order-3 md:order-2 mb-6 md:mb-0">
        {!!business.contactEmail && <p>{business.contactEmail}</p>}
        {!!business.businessNumber && (
          <p>
            <span className="mr-1">
              <FormattedMessage id="INVOICE_ABN" />
            </span>
            <span>{business.businessNumber}</span>
          </p>
        )}
        {!!business.physicalAddress && <p>{business.physicalAddress}</p>}
        {!!business.phoneNumber && (
          <p>
            <span className="mr-1">
              <FormattedMessage id="INVOICE_PHONE" />
            </span>
            <span>{business.phoneNumber}</span>
          </p>
        )}
        {!!business.website && (
          <p>
            <a
              className="underline text-blue-400"
              href={business.website}
              rel="noreferrer"
              target="_blank">
              {business.website}
            </a>
          </p>
        )}
      </div>

      <h2
        className={classNames(
          'text-3xl font-bold order-2 md:order-3 mb-6 md:mb-0 md:mt-12',
          `text-${theme.color}`,
          `font-${theme.font}`
        )}>
        {business.businessName}
      </h2>
      <h2
        className={classNames(
          'text-3xl font-bold order-4 mb-6 md:mb-0 md:mt-12 md:text-right',
          `text-${theme.color}`,
          `font-${theme.font}`
        )}>
        <FormattedMessage id={isGstRegistered ? 'INVOICE_TAX_INVOICE' : 'INVOICE_NOTAX_INVOICE'} />
      </h2>
    </div>
  );
};

export default InvoiceHeader;

import React, { PropsWithChildren, useContext, useState } from 'react';
import Customization from 'models/customization';

const DEFAULT_THEME = {
  logo: '',
  color: '#000A1E',
  font: 'roboto',
  paymentInstructions: ''
};

export interface InvoiceThemeContextType {
  theme: Customization;
  setTheme: (theme: Customization) => void;
}

export const InvoiceThemeContext = React.createContext<InvoiceThemeContextType>({
  theme: DEFAULT_THEME,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setTheme: (_: Customization) => {}
});

export const useThemeContext = () => useContext(InvoiceThemeContext);

const InvoiceThemeContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Customization>(DEFAULT_THEME);

  const handleThemeUpdate = (theme: Customization) => {
    const formattedTheme = Object.assign({}, theme, {
      font: theme.font?.replace(/\s/g, '').toLowerCase() || DEFAULT_THEME.font,
      color: theme.color?.toUpperCase() || DEFAULT_THEME.color
    });

    setTheme(formattedTheme);
  };

  return (
    <InvoiceThemeContext.Provider value={{ theme, setTheme: handleThemeUpdate }}>
      {children}
    </InvoiceThemeContext.Provider>
  );
};

export default InvoiceThemeContextProvider;

import { joinWithSeparator } from 'helpers/string-helper';
import Address from 'models/address';
import Invoice from 'models/invoice';
import Phone from 'models/phone';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Customization from 'models/customization';

export interface InvoiceDetailsProps {
  invoice: Invoice;
  theme: Customization;
  className?: string;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ invoice, theme, className }) => {
  const { contact } = invoice;

  const getFormattedAddress = (address: Address) => {
    return joinWithSeparator(
      ' ',
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4
    );
  };

  const getFormattedRegionAndCity = (address: Address) => {
    return joinWithSeparator(
      ', ',
      address.region,
      address.city,
      address.postalCode,
      address.country
    );
  };

  const getFormattedPhone = (phone: Phone) => {
    const countryCodeText = phone.phoneCountryCode ? ` (${phone.phoneCountryCode})` : '';
    const phoneNumberText = phone.phoneNumber ? ` ${phone.phoneNumber}` : '';

    return countryCodeText + phoneNumberText;
  };

  return (
    <div className={className}>
      <h3
        className={classNames(
          'text-lg font-semibold whitespace-pre mb-1 md:mb-3',
          `text-${theme.color}`,
          `font-${theme.font}`
        )}>
        <FormattedMessage id="INVOICE_TO" />
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="mb-6 md:mb-0 pr-2">
          <p className="font-semibold py-1 whitespace-pre-line break-words">{contact.name}</p>
          {(contact.firstName || contact.lastName) && (
            <p className="font-semibold py-1 whitespace-pre-line break-words">
              {joinWithSeparator(' ', contact.firstName, contact.lastName)}
            </p>
          )}
          {!!contact.email && (
            <p className="text-muted py-1 whitespace-pre-line break-words">{contact.email}</p>
          )}
          {!!contact.billingAddress && (
            <p className="text-muted py-1 whitespace-pre-line break-words">
              {getFormattedAddress(contact.billingAddress)}
            </p>
          )}
          {!!contact.billingAddress && (
            <p className="text-muted py-1 whitespace-pre-line break-words">
              {getFormattedRegionAndCity(contact.billingAddress)}
            </p>
          )}
          {(!!contact.phone.phoneNumber || !!contact.phone.phoneCountryCode) && (
            <p className="text-muted whitespace-pre-line break-words">
              <span className="mr-1">
                <FormattedMessage id="INVOICE_PHONE" />
              </span>
              <span>{getFormattedPhone(contact.phone)}</span>
            </p>
          )}
        </div>
        <div className="mb-6 md:mb-0 pr-2">
          <div className="grid grid-cols-[100px_minmax(0,_1fr)] py-1">
            <div
              className={classNames('font-semibold', `text-${theme.color}`, `font-${theme.font}`)}>
              <FormattedMessage id="INVOICE_NUMBER" />
            </div>
            <div className="text-gray-600 text-right md:text-left">
              {invoice.number ? `#${invoice.number}` : '#'}
            </div>
          </div>

          <div className="grid grid-cols-[100px_minmax(0,_1fr)] py-1">
            <div
              className={classNames('font-semibold', `text-${theme.color}`, `font-${theme.font}`)}>
              <FormattedMessage id="INVOICE_ISSUED" />
            </div>
            <div className="text-gray-600 text-right md:text-left">
              {invoice.datedDay && (
                <FormattedDate
                  value={invoice.datedDay}
                  day="numeric"
                  month="short"
                  year="numeric"
                />
              )}
            </div>
          </div>

          <div className="grid grid-cols-[100px_minmax(0,_1fr)] py-1">
            <div
              className={classNames('font-semibold', `text-${theme.color}`, `font-${theme.font}`)}>
              <FormattedMessage id="INVOICE_DUE" />
            </div>
            <div className="text-gray-600 text-right md:text-left">
              {invoice.dueDay && (
                <FormattedDate value={invoice.dueDay} day="numeric" month="short" year="numeric" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;

import InvoiceStatus from 'enums/invoice-status';
import InvoiceTaxMode from 'enums/invoice-tax-mode';
import InvoiceTaxType from 'enums/invoice-tax-type';
import { toMoney } from 'helpers/currency-helper';
import PreviewInfo from 'models/preview-info';

const PREVIEW_TEMPLATE: PreviewInfo = {
  invoice: {
    id: '',
    contact: {
      name: 'Client name',
      physicalAddress: 'Client street address \n Suburb, State, Post code',
      email: 'clientname@client.com',
      phone: {
        phoneAreaCode: '',
        phoneCountryCode: '',
        phoneNumber: '(123) 456 7890'
      },
      billingAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        city: '',
        region: '',
        postalCode: '',
        country: ''
      }
    },
    attachments: [
      {
        name: 'Attachment1.jpg',
        referenceId: '212A575B-3688-4481-9EE3-E19FB701A37E',
        type: 'jpg',
        uploadDate: new Date('2021-07-04')
      },
      {
        name: 'Attachment2.jpg',
        referenceId: '212A575B-3688-4481-9EE3-E19FB701A37A',
        type: 'jpg',
        uploadDate: new Date('2021-07-04')
      }
    ],
    number: '1',
    datedDay: new Date('2021-11-11'),
    dueDay: new Date('2021-11-25'),
    note: 'Thank you for your business.',
    items: [
      {
        name: 'item 1',
        description: 'This is an item description',
        quantity: 1,
        price: toMoney(100, 'GBP'),
        amount: toMoney(100, 'GBP'),
        taxRate: 10,
        taxType: InvoiceTaxType.GSTOnIncome,
        chartOfAccount: ''
      },
      {
        name: 'item 2',
        description: '',
        quantity: 1,
        price: toMoney(100, 'GBP'),
        amount: toMoney(100, 'GBP'),
        taxRate: 0,
        taxType: InvoiceTaxType.BASExcluded,
        chartOfAccount: ''
      }
    ],
    status: InvoiceStatus.Unpaid,
    subTotal: toMoney(0, 'GBP'),
    taxMode: InvoiceTaxMode.Exclusive,
    taxAmount: toMoney(0, 'GBP')
  },
  business: {
    businessName: 'Business name',
    businessNumber: 'number',
    physicalAddress: 'Business street address \n Suburb, State, Post code',
    website: 'www.mywebsite.com',
    contactEmail: 'name@business.com',
    phoneNumber: '(123) 456 7890',
    isGstRegistered: true
  },
  settings: {
    logo: '',
    paymentInstructions: `
    Please make payment via direct deposit to:
    Account name
    BSB: xxx-xxx
    Account xxxxxx
    `,
    color: '#00823C',
    font: 'Roboto, Nunito, Source Serif Pro, Arvo, sans-serif'
  }
};
export default PREVIEW_TEMPLATE;

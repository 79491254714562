import InvoiceTaxMode from 'enums/invoice-tax-mode';
import InvoiceTaxType from 'enums/invoice-tax-type';
import { useMoneyCurrency } from 'hooks/useMoneyCurrency';
import InvoiceItem from 'models/invoice-item';
import { useMemo } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import classNames from 'classnames';
import Customization from 'models/customization';
import Money from 'models/money';

export interface InvoiceItemsProps {
  items: InvoiceItem[];
  isGstRegistered?: boolean;
  taxMode: InvoiceTaxMode;
  totalAmount: Money;
  theme: Customization;
  className?: string;
}

const InvoiceItems: React.FC<InvoiceItemsProps> = ({
  items,
  isGstRegistered,
  taxMode,
  totalAmount,
  theme,
  className
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const moneyCurrency = useMoneyCurrency();

  const labels = useMemo(
    () => ({
      name: formatMessage({ id: 'INVOICE_ITEM_NAME' }),
      quantity: formatMessage({ id: 'INVOICE_ITEM_QUANTITY' }),
      price: formatMessage({ id: 'INVOICE_ITEM_PRICE' }),
      tax: formatMessage({ id: 'INVOICE_ITEM_TAX' }),
      amount: formatMessage({ id: 'INVOICE_ITEM_AMOUNT' }),
      subTotal:
        taxMode === InvoiceTaxMode.Inclusive
          ? formatMessage({ id: 'INVOICE_SUMMARY_SUBTOTAL_TAX_INCLUSIVE' })
          : formatMessage({ id: 'INVOICE_SUMMARY_SUBTOTAL_TAX_EXCLUSIVE' }),
      total: formatMessage({ id: 'INVOICE_SUMMARY_TOTAL' })
    }),
    [taxMode]
  );

  const headers = useMemo(
    () => [
      {
        className: classNames('text-left py-3', `text-${theme.color}`, `font-${theme.font}`),
        value: labels.name
      },
      {
        className: classNames(
          'text-right py-3 w-1/5',
          'hidden md:table-cell',
          `text-${theme.color}`,
          `font-${theme.font}`
        ),
        value: labels.quantity
      },
      {
        className: classNames(
          'text-right py-3 w-1/5',
          'hidden md:table-cell',
          `text-${theme.color}`,
          `font-${theme.font}`
        ),
        value: labels.price
      },
      {
        className: classNames('text-right py-3', `text-${theme.color} font-${theme.font}`),
        hide: !isGstRegistered,
        value: labels.tax
      },
      {
        className: classNames('text-right py-3 w-1/5', `text-${theme.color} font-${theme.font}`),
        value: labels.amount
      }
    ],
    [labels, theme]
  );

  const getTaxRate = (taxType: InvoiceTaxType, taxRate: number) => {
    switch (taxType) {
      case InvoiceTaxType.BASExcluded:
        return formatMessage({ id: 'INVOICE_ITEM_VALUE_NO_GST' });
      case InvoiceTaxType.GSTFree:
        return formatMessage({ id: 'INVOICE_ITEM_VALUE_GST_FREE' });
      case InvoiceTaxType.NoVAT:
        return '';
      default:
        return formatNumber(taxRate / 100, {
          style: 'percent'
        });
    }
  };

  return (
    <div className={classNames('', className)}>
      <table className={classNames('w-full', `font-${theme.font}`)}>
        <thead className={classNames('border-b-2', `border-b-${theme.color}`)}>
          <tr>
            {headers.map(
              (x) =>
                !x.hide && (
                  <th key={x.value} className={x.className} aria-label={x.value}>
                    {x.value}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => (
            <tr
              key={`${item.name}-${idx}`}
              className="text-left py-3 border-b border-b-gray-300 text-muted">
              <td className="text-left py-3">
                <p>{item.name}</p>
                {item.description && <p className="text-sm text-faint">{item.description}</p>}
                <p className="md:hidden">
                  {item.quantity} x {moneyCurrency(item.price)}
                </p>
              </td>
              <td className="text-right py-3 align-text-top hidden md:table-cell">
                <FormattedNumber style="decimal" value={item.quantity} />
              </td>
              <td className="text-right py-3 align-text-top hidden md:table-cell">
                {moneyCurrency(item.price)}
              </td>
              {isGstRegistered && <td>{getTaxRate(item.taxType, item.taxRate)}</td>}
              <td className="text-right py-3 align-text-top">{moneyCurrency(item.amount)}</td>
            </tr>
          ))}
          <tr>
            <td className="hidden md:table-cell" colSpan={2} />
            <td colSpan={2} className={classNames('border-y', `border-y-${theme.color}`)}>
              <div
                className={classNames(
                  'flex flex-row justify-between font-semibold py-3',
                  `text-${theme.color}`,
                  `font-${theme.font}`
                )}>
                <span>{labels.total}</span>
                <span>{moneyCurrency(totalAmount)}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItems;

import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

const LocalisationWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [locale] = useState('en-NZ');
  const [messages, setMessages] = useState<Record<string, string> | null>(null);

  const loadMessages = (locale: string) => {
    switch (locale) {
      default:
        return import('./en-NZ.json');
    }
  };

  useEffect(() => {
    loadMessages(locale).then((msgs: unknown) => {
      setMessages(msgs as Record<string, string>);
    });
  }, [locale]);

  if (!messages) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LocalisationWrapper;

import classNames from 'classnames';

export type CardProps = {
  className?: string;
  children?: React.ReactNode;
};

const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={classNames('inline-block rounded-sm bg-white p-4', className)}>{children}</div>
  );
};

export default Card;

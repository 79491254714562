import React, { useMemo } from 'react';
import { ButtonLink, Card } from 'components/ui';
import classNames from 'classnames';
import { FormattedDate, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import InvoiceStatus from 'enums/invoice-status';
import { useMoneyCurrency } from 'hooks/useMoneyCurrency';
import Money from 'models/money';
import { ArrowDownIcon } from '@heroicons/react/24/solid';

export interface InvoiceOverviewProps {
  dueInDays: number;
  dueDay?: Date;
  paidDate?: Date;
  status: InvoiceStatus;
  totalAmount: Money;
  className?: string;
  downloadUrl?: string;
  downloadFileName?: string;
}

const InvoiceOverview: React.FC<InvoiceOverviewProps> = ({
  dueInDays,
  dueDay,
  paidDate,
  status,
  totalAmount,
  className,
  downloadUrl,
  downloadFileName
}) => {
  const formatCurrency = useMoneyCurrency();
  const isPaid = status.toLowerCase() === InvoiceStatus.Paid;
  const isOverdue = !isPaid && dueInDays !== undefined && dueInDays < 0;
  const textColorClass = useMemo(() => {
    if (isPaid) {
      return 'text-status-paid';
    }

    if (isOverdue) {
      return 'text-status-overdue';
    }

    return 'text-status-unpaid';
  }, [isPaid, isOverdue]);

  return (
    <div className={classNames('', className)}>
      <Card className="w-full px-10 py-7">
        <div className="flex">
          <div className="flex-1">
            <p className="font-extrabold text-md md:text-xl">{formatCurrency(totalAmount)}</p>
            <div className="font-extrabold text-md md:text-xl">
              {dueDay || isPaid ? (
                <p className={textColorClass}>
                  <FormattedMessage
                    id={isPaid ? 'INVOICE_OVERVIEW_STATUS_PAID' : 'INVOICE_OVERVIEW_STATUS_UNPAID'}
                  />
                  {!isPaid && !isOverdue && dueDay && (
                    <FormattedDate value={dueDay} day="numeric" month="short" year="numeric" />
                  )}
                  {isPaid && paidDate !== undefined && (
                    <FormattedDate value={paidDate} day="numeric" month="short" year="numeric" />
                  )}
                  {isOverdue && dueInDays !== undefined && (
                    <FormattedRelativeTime unit="day" value={dueInDays} />
                  )}
                </p>
              ) : (
                <p className="text-blue-950">
                  <FormattedMessage id="INVOICE_OVERVIEW_BALANCE_DUE" />
                </p>
              )}
            </div>
          </div>
          <div className="pl-2 flex items-center">
            {!!downloadUrl && !!downloadFileName && (
              <ButtonLink href={downloadUrl} download={downloadFileName}>
                <div className="flex items-center">
                  <ArrowDownIcon className="pr-1 h-5 w-5" />
                  <span>Download</span>
                  <span className="hidden md:inline">&nbsp;PDF</span>
                </div>
              </ButtonLink>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InvoiceOverview;

import InvoiceTaxType from 'enums/invoice-tax-type';
import Money from './money';
import { isMoney, toMoney } from 'helpers/currency-helper';
import { getTaxType } from 'helpers/enum-helper';

export default interface InvoiceItem {
  quantity: number;
  name: string;
  description: string;
  price: Money;
  taxRate: number;
  amount: Money;
  chartOfAccount: string;
  taxType: InvoiceTaxType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseInvoiceItem = (value: Record<string, any>): InvoiceItem => ({
  quantity: Number(value?.quantity) ?? 0,
  name: value?.name ?? '',
  description: value?.description ?? '',
  price: isMoney(value.price) ? value.price : toMoney(0, 'GBP'),
  taxRate: value?.taxRate ?? 0,
  amount: isMoney(value.amount) ? value.amount : toMoney(0, 'GBP'),
  chartOfAccount: value?.chartOfAccount ?? '',
  taxType: getTaxType(value.taxType)
});

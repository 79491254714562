import React, { useMemo } from 'react';
import Invoice from 'models/invoice';
import PaymentMode from 'enums/payment-mode';
import InvoiceStatus from 'enums/invoice-status';
import { PlainInvoice, StripeInvoice } from '../index';

export interface InvoiceViewSwitcherProps {
  invoice: Invoice;
  downloadUrl?: string;
  downloadFileName?: string;
}

const InvoiceViewSwitcher: React.FC<InvoiceViewSwitcherProps> = ({
  invoice,
  downloadUrl,
  downloadFileName
}) => {
  const { paymentModes, stripePaymentConfig, status } = invoice;

  const isStripeEnabled = useMemo(
    () => (paymentModes || []).includes(PaymentMode.Stripe),
    [paymentModes]
  );
  const isStripePaymentConfigComplete = useMemo(
    () =>
      !!(
        stripePaymentConfig &&
        stripePaymentConfig.paymentIntentClientSecret &&
        stripePaymentConfig.publishedKey &&
        stripePaymentConfig.stripeAccountId
      ),
    [stripePaymentConfig]
  );
  const isInvoicePaid = useMemo(() => status == InvoiceStatus.Paid, [status]);

  const shouldShowStripeInvoice =
    isStripeEnabled && isStripePaymentConfigComplete && !isInvoicePaid;

  return shouldShowStripeInvoice && !isInvoicePaid ? (
    <StripeInvoice
      invoice={invoice}
      downloadUrl={downloadUrl}
      downloadFileName={downloadFileName}
    />
  ) : (
    <PlainInvoice invoice={invoice} downloadUrl={downloadUrl} downloadFileName={downloadFileName} />
  );
};

export default InvoiceViewSwitcher;

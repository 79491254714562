import AppContextProvider from 'context/app';
import { ConfigContext, ConfigContextType } from 'context/config';
import { registerGlobalLite } from 'externals/lite';
import LocalisationWrapper from 'i18n/localisation-wrapper';
import { useContext, useEffect } from 'react';
import AppRouter from 'router';
import AppConfig from 'models/app-config';
import InvoiceThemeContextProvider from 'context/invoice-theme';

const App = () => {
  const { setConfig } = useContext(ConfigContext) as ConfigContextType;

  const mapEnvConfig = (): AppConfig => {
    return {
      env: process.env.REACT_APP_ENV as string,
      bff: {
        url: process.env.REACT_APP_API_BASE_URL as string
      }
    };
  };

  useEffect(() => {
    setConfig(mapEnvConfig());
    registerGlobalLite();
  }, []);

  return (
    <LocalisationWrapper>
      <AppContextProvider>
        <InvoiceThemeContextProvider>
          <AppRouter />
        </InvoiceThemeContextProvider>
      </AppContextProvider>
    </LocalisationWrapper>
  );
};

export default App;

import axios from 'axios';
import Invoice from 'models/invoice';
import AppConfig from 'models/app-config';
import { unpaidInvoice } from '__mocks__/invoice';

export default class InvoiceService {
  constructor(private readonly config: AppConfig) {}

  public async getInvoice(invoiceId: string, preview: string): Promise<Invoice | null> {
    if (this.config.env === 'dev') {
      return new Promise((resolve) => {
        return resolve(unpaidInvoice);
      });
    }

    const url = `${this.config.bff.url}/OnlineInvoices/${invoiceId}?preview=${preview}`;
    const response = await axios.get<Invoice | null>(url);
    const invoice = response.data || null;

    console.log('INVOICE DATA: ', invoice);

    if (invoice !== null) {
      invoice.id = invoiceId;
    }

    return invoice;
  }
}

import React from 'react';
import Invoice from 'models/invoice';
import InvoiceOverview from '../invoice-overview';
import OnlineInvoice from '../online-invoice';
import classNames from 'classnames';

export interface PlainInvoiceProps {
  invoice: Invoice;
  className?: string;
  downloadUrl?: string;
  downloadFileName?: string;
}

const PlainInvoice: React.FC<PlainInvoiceProps> = ({
  invoice,
  className,
  downloadFileName,
  downloadUrl
}) => {
  return (
    <div className="w-full md:w-[50rem] xl:w-[80rem] mx-auto">
      <InvoiceOverview
        className="md:mx-10 mt-6"
        dueInDays={invoice.dueInDays}
        dueDay={invoice.dueDay}
        paidDate={invoice.paidDate}
        status={invoice.status}
        totalAmount={invoice.totalAmount}
        downloadFileName={downloadFileName}
        downloadUrl={downloadUrl}
      />
      <OnlineInvoice
        className={classNames('md:px-10 py-10', className)}
        invoice={invoice}
        showPaymentDetails={true}
      />
    </div>
  );
};

export default PlainInvoice;

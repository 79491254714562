import React from 'react';
import { FormattedMessage } from 'react-intl';

const StripePaymentDivider: React.FC = () => {
  return (
    <div className="pt-7">
      <h2 className="border-b border-gray-400 text-center leading-[0.1rem] mt-2.5 mb-2.5">
        <span className="bg-white px-2.5 font-bold">
          <FormattedMessage id="ADDITIONAL_PAYMENT_METHOD_OR" />
        </span>
      </h2>
    </div>
  );
};

export default StripePaymentDivider;

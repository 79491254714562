import OnlineInvoice from '../online-invoice';
import { StripePanel } from '../../stripe';
import Invoice from '../../../../models/invoice';
import classNames from 'classnames';
import InvoiceOverview from '../invoice-overview';
import React from 'react';

export interface StripeInvoiceProps {
  invoice: Invoice;
  className?: string;
  downloadUrl?: string;
  downloadFileName?: string;
}

const StripeInvoice: React.FC<StripeInvoiceProps> = ({
  invoice,
  className,
  downloadUrl,
  downloadFileName
}) => {
  return (
    <div className="w-full md:w-[50rem] xl:w-[80rem] mx-auto">
      <InvoiceOverview
        className="mt-6"
        dueInDays={invoice.dueInDays}
        dueDay={invoice.dueDay}
        paidDate={invoice.paidDate}
        status={invoice.status}
        totalAmount={invoice.totalAmount}
        downloadFileName={downloadFileName}
        downloadUrl={downloadUrl}
      />
      <div className={classNames('w-full grid grid-cols-1 xl:grid-cols-5 mt-10', className)}>
        <div className="xl:col-span-3 order-2 xl:order-1">
          <OnlineInvoice
            invoice={invoice}
            showPaymentDetails={false}
            className="xl:pr-10 mt-10 xl:mt-0 flex-1"
          />
        </div>
        <div className="xl:col-span-2 order-1 xl:order-2">
          <StripePanel invoice={invoice} className="basis-2/5" />
        </div>
      </div>
    </div>
  );
};

export default StripeInvoice;

import { ReactComponent as ArchiveIcon } from 'components/assets/icons/archive.svg';
import { ReactComponent as DocumentIcon } from 'components/assets/icons/document.svg';
import { ReactComponent as ImageIcon } from 'components/assets/icons/image.svg';
import { ReactComponent as PdfIcon } from 'components/assets/icons/pdf.svg';
import { ReactComponent as PlainIcon } from 'components/assets/icons/plain.svg';
import { ReactComponent as SpreadsheetIcon } from 'components/assets/icons/spreadsheet.svg';

const FILE_ICON_MAP: Record<string, string> = {
  bmp: 'image',
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  tif: 'image',
  tiff: 'image',
  pdf: 'pdf',
  key: 'pdf',
  ppt: 'pdf',
  pptx: 'pdf',
  doc: 'document',
  docx: 'document',
  pages: 'document',
  rtf: 'document', // RTF, RTF/TEXT
  odt: 'document',
  odf: 'document',
  csv: 'spreadsheet',
  numbers: 'spreadsheet',
  ods: 'spreadsheet',
  xls: 'spreadsheet',
  xlsx: 'spreadsheet',
  zip: 'archive',
  rar: 'archive',
  '7z': 'archive',
  eml: 'plain',
  msg: 'plain',
  txt: 'plain'
};

export const getFileIconName = (fileExtension: string) => {
  return FILE_ICON_MAP[fileExtension.toLowerCase()] || FILE_ICON_MAP.txt;
};

export const getFileIconComponent = (fileExtension: string) => {
  const iconName = getFileIconName(fileExtension);

  switch (iconName) {
    case 'archive':
      return ArchiveIcon;
    case 'document':
      return DocumentIcon;
    case 'image':
      return ImageIcon;
    case 'pdf':
      return PdfIcon;
    case 'spreadsheet':
      return SpreadsheetIcon;
    default:
      return PlainIcon;
  }
};

import classNames from 'classnames';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <button
      className={classNames(
        'min-h-10 rounded-sm leading-4 px-4 py-3 bg-blue-300 text-white font-sans font-bold disabled:opacity-70',
        className
      )}
      {...otherProps}>
      {children}
    </button>
  );
};

export default Button;

import Money from 'models/money';

const NANO_FACTOR = 1_000_000_000;

const CURRENCY_SYMBOL_MAP: Record<string, string> = {
  AUD: '$',
  GBP: '£'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMoney = (value: Record<string, any>): boolean => {
  if (!value) {
    return false;
  }

  return (
    Object.hasOwn(value, 'currencyCode') &&
    Object.hasOwn(value, 'units') &&
    Object.hasOwn(value, 'nanos')
  );
};

export const toMoney = (value: number, currencyCode: string): Money => {
  const tempUnits = Math.floor(Math.abs(value));
  let units = tempUnits;

  if (value < 0 && tempUnits !== 0) {
    units = -tempUnits;
  }

  const tempNanos = Math.ceil(Math.abs((value - units) * NANO_FACTOR));
  let nanos = tempNanos;

  if (value < 0 && tempNanos !== 0) {
    nanos = -tempNanos;
  }

  return {
    currencyCode,
    units,
    nanos
  };
};

export const toDecimal = (value: Money): number => {
  return value.units + value.nanos / NANO_FACTOR;
};

export const getCurrencySymbol = (value: Money): string => {
  const symbol = CURRENCY_SYMBOL_MAP[value.currencyCode];

  if (!symbol) {
    throw new Error('Unsupported currency code');
  }

  return symbol;
};

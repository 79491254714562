import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  PaymentElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { PaymentRequest, Stripe } from '@stripe/stripe-js';
import Invoice from 'models/invoice';
import { FormattedMessage, useIntl } from 'react-intl';
import { toDecimal } from 'helpers/currency-helper';
import StripePaymentDivider from '../stripe-payment-divider';
import { Button } from 'components/ui';
import { TailSpin, ThreeDots } from 'react-loader-spinner';

export interface StripeCheckoutForm {
  invoice: Invoice;
  onPaymentSuccess?: () => void;
}

const StripeCheckoutForm: React.FC<StripeCheckoutForm> = ({ invoice, onPaymentSuccess }) => {
  const { stripePaymentConfig } = invoice;
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [, setIsPaySuccess] = useState(false);
  const [message, setMessage] = useState<string>();
  const { formatMessage } = useIntl();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [isSecretFromRedirectUrl, setIsSecretFromRedirectUrl] = useState(false);

  //refer to: https://stripe.com/docs/stripe-js/elements/payment-request-button
  const setUpPaymentRequestCallback = useCallback(
    (pr: PaymentRequest, strp: Stripe, clientSecret: string) => {
      pr.on('paymentmethod', async (ev) => {
        const { paymentIntent, error: confirmError } = await strp.confirmCardPayment(
          clientSecret,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          if (paymentIntent && paymentIntent.status === 'requires_action') {
            const { error } = await strp.confirmCardPayment(clientSecret);
            if (error) {
              setMessage(error.message);
            } else {
              window.location.reload();
            }
          } else {
            window.location.reload();
          }
        }
      });
    },
    []
  );

  const initStripe = useCallback(
    (stripe: Stripe, paymentRequest: PaymentRequest, clientSecret: string) => {
      // Check the availability of the Payment Request API.
      paymentRequest
        .canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(paymentRequest);
            setUpPaymentRequestCallback(paymentRequest, stripe, clientSecret);
          }
        })
        .catch((e) => console.log(e));

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (!paymentIntent) {
          return;
        }

        switch (paymentIntent.status) {
          case 'succeeded':
          case 'processing':
            setIsPaySuccess(true);
            if (onPaymentSuccess) {
              onPaymentSuccess();
            }
            break;
          case 'requires_payment_method':
            if (isSecretFromRedirectUrl) {
              setMessage(formatMessage({ id: 'STRIPE_PAYMENT_NOT_SUCCESSFUL' }));
            }
            break;
          default:
            setMessage(formatMessage({ id: 'STRIPE_UNEXPECTED_ERROR' }));
            break;
        }
      });
    },
    [formatMessage]
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return_url: window.location.href
      }
    });

    if (
      error.type === 'card_error' ||
      error.type === 'validation_error' ||
      error.type === 'invalid_request_error'
    ) {
      setMessage(error.message);
    } else {
      setMessage(formatMessage({ id: 'STRIPE_UNEXPECTED_ERROR' }));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const getClientSecret = () => {
      const clientSecretInUrl = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      const clientSecretInInvoice = stripePaymentConfig?.paymentIntentClientSecret;

      if (!clientSecretInUrl && !clientSecretInInvoice) {
        return null;
      }

      if (clientSecretInUrl) {
        setIsSecretFromRedirectUrl(true);
      }

      return clientSecretInUrl ?? clientSecretInInvoice;
    };

    const clientSecret = getClientSecret();

    if (!clientSecret) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: formatMessage({
          id: 'STRIPE_APPLE_GOOGLE_PAY_TOTAL_AMOUNT_LABEL'
        }),
        //Stripe works in subunits. so total amount needs to be * 100
        //Take 2.3 as example, 2.3 * 100 will return 229.99999999999997 which is incorrect, use Math.round to fix it
        //https://stackoverflow.com/questions/13248173/why-does-230-100100-not-return-230
        amount: Math.round(toDecimal(invoice.totalAmount) * 100)
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    initStripe(stripe, pr, clientSecret);
  }, [stripe, stripePaymentConfig, formatMessage]);

  return (
    <form id="payment-form" name="payment-form" onSubmit={handleSubmit}>
      {paymentRequest && (
        <React.Fragment>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <StripePaymentDivider />
        </React.Fragment>
      )}
      <PaymentElement
        className="py-10"
        id="payment-element"
        options={{
          wallets: {
            applePay: 'never',
            googlePay: 'never'
          }
        }}
        onReady={() => setIsReady(true)}
      />
      <div className="flex justify-center">
        <ThreeDots
          height="50"
          width="50"
          radius="9"
          color="#404756"
          ariaLabel="three-dots-loading"
          visible={!isReady}
        />
      </div>
      {isReady && (
        <Button
          className="w-full"
          disabled={isLoading || !stripe || !elements || !isReady}
          id="submit"
          name="submit"
          type="submit">
          <div className="flex justify-center" id="button-text">
            {isLoading ? (
              <TailSpin height="16" width="16" color="#fff" />
            ) : (
              <FormattedMessage id="STRIPE_PAYMENT_PAY_NOW" />
            )}
          </div>
        </Button>
      )}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default StripeCheckoutForm;

import React, { useMemo, useState } from 'react';
import Invoice from 'models/invoice';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import StripePanelHeader from '../stripe-panel-header';
import classNames from 'classnames';
import { Card } from 'components/ui';
import StripeCheckoutForm from '../stripe-checkout-form';
import { StripePanelFooter } from '../index';
import StripePaymentSuccess from '../stripe-payment-success';
import { StripePaymentConfig } from 'models/stripe-payment-config';

export interface StripePanelProps {
  invoice: Invoice;
  className?: string;
}

const StripePanel: React.FC<StripePanelProps> = ({ invoice, className }) => {
  const stripePaymentConfig = invoice.stripePaymentConfig as StripePaymentConfig;

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const stripePromise = loadStripe(stripePaymentConfig.publishedKey, {
    stripeAccount: stripePaymentConfig.stripeAccountId
  });

  const appearance: Appearance = {
    theme: 'stripe'
  };

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret: stripePaymentConfig.paymentIntentClientSecret,
      appearance
    }),
    [stripePaymentConfig]
  );

  return (
    <div className={classNames('', className)}>
      <Card className="py-7 px-10 w-full">
        <Elements stripe={stripePromise} options={options}>
          {paymentSuccess ? (
            <StripePaymentSuccess />
          ) : (
            <React.Fragment>
              <StripePanelHeader
                dueDate={invoice.dueDay}
                dueInDays={invoice.dueInDays}
                totalAmount={invoice.totalAmount}
              />
              <StripeCheckoutForm
                invoice={invoice}
                onPaymentSuccess={() => setPaymentSuccess(true)}
              />
            </React.Fragment>
          )}
        </Elements>
      </Card>
      <StripePanelFooter className="mt-1" />
    </div>
  );
};

export default StripePanel;

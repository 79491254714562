import { Card } from 'components/ui';
import classNames from 'classnames';
import InvoiceHeader from '../invoice-header';
import Invoice from 'models/invoice';
import InvoiceDetails from '../invoice-details';
import InvoiceItems from '../invoice-items';
import InvoiceSection from '../invoice-section';
import InvoiceAttachments from '../invoice-attachments';
import InvoiceSentBy from '../invoice-sent-by';
import { useIntl } from 'react-intl';
import { useThemeContext } from 'context/invoice-theme';

export interface OnlineInvoiceProps {
  invoice: Invoice;
  className?: string;
  showPaymentDetails?: boolean;
  readonly?: boolean;
}

const OnlineInvoice: React.FC<OnlineInvoiceProps> = ({
  invoice,
  className,
  showPaymentDetails,
  readonly
}) => {
  const { formatMessage } = useIntl();
  const { theme } = useThemeContext();

  return (
    <div className={classNames(`font-${theme.font}`, className)}>
      <Card className="rounded-sm w-full py-10 px-6 xl:py-20 xl:px-10">
        <InvoiceHeader
          business={invoice.business}
          isGstRegistered={invoice.isGstRegistered}
          theme={theme}
        />
        <InvoiceDetails className="md:mt-12" invoice={invoice} theme={theme} />
        <InvoiceItems
          className="md:mt-12 mb-12"
          items={invoice.items}
          isGstRegistered={invoice.isGstRegistered}
          taxMode={invoice.taxMode}
          totalAmount={invoice.totalAmount}
          theme={theme}
        />
        <div className="flex flex-col md:flex-row">
          {!!theme.paymentInstructions && showPaymentDetails && (
            <InvoiceSection
              className={classNames('flex-1 mb-6 md:mb-0', `text-${theme.color}`)}
              label={formatMessage({ id: 'INVOICE_PAYMENT_DETAILS' })}>
              {invoice.customization.paymentInstructions}
            </InvoiceSection>
          )}
          {!!invoice.note && (
            <InvoiceSection
              className={classNames('flex-1', `text-${theme.color}`)}
              label={formatMessage({ id: 'INVOICE_NOTE' })}>
              {invoice.note}
            </InvoiceSection>
          )}
        </div>
        {!!invoice.attachments.length && (
          <InvoiceAttachments
            className="mt-6 md:mt-12"
            invoiceId={invoice.id}
            attachments={invoice.attachments}
            theme={theme}
            readonly={readonly}
          />
        )}
      </Card>
      <InvoiceSentBy className="mt-10 mb-6" />
    </div>
  );
};

export default OnlineInvoice;

import { useEffect, useState } from 'react';
import Invoice, { parseInvoice } from 'models/invoice';
import OnlineInvoice from 'components/ui/invoice/online-invoice';
import { PageLoader } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import PREVIEW_TEMPLATE from 'constants/invoice-preview-template';
import Customization from 'models/customization';
import InvoiceTaxMode from 'enums/invoice-tax-mode';
import { toMoney } from 'helpers/currency-helper';
import Business from 'models/business';
import PreviewInfo from 'models/preview-info';
import { removeLiteProperty, setLiteProperty } from 'externals/lite';
import PreviewMode from 'enums/preview-mode';
import { useThemeContext } from 'context/invoice-theme';

const PreviewPage = () => {
  const { setTheme } = useThemeContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [isCustomizationTemplate, setIsCustomizationTemplate] = useState(false);

  const getPreviewData = (customization: Customization, business: Business) =>
    Object.assign({}, PREVIEW_TEMPLATE, {
      invoice: {
        ...PREVIEW_TEMPLATE.invoice,
        taxMode: business.isGstRegistered ? InvoiceTaxMode.Exclusive : InvoiceTaxMode.NoTax,
        subTotal: toMoney(200, 'GBP'),
        taxAmount: toMoney(business.isGstRegistered ? 10 : 0, 'GBP'),
        totalAmount: toMoney(business.isGstRegistered ? 210 : 200, 'GBP'),
        isGstRegistered: business.isGstRegistered
      },
      business,
      settings: {
        ...customization,
        paymentInstructions: PREVIEW_TEMPLATE.settings?.paymentInstructions ?? ''
      },
      isCustomizationTemplate: true
    });

  const preview = (previewInfo: PreviewInfo) => {
    const { invoice, business, settings } = previewInfo;

    if (!invoice) {
      return;
    }

    const data: Invoice = parseInvoice(invoice, business, settings);

    setInvoice(data);
    setIsCustomizationTemplate(previewInfo.isCustomizationTemplate ?? false);
    setLoading(false);
    setTheme(data.customization);
  };

  const customTheme = (customization: Customization, business: Business) => {
    const data = getPreviewData(customization, business);
    preview(data);
  };

  useEffect(() => {
    window.lite.switchPreviewMode(PreviewMode.Web);
    setLiteProperty('preview', preview);
    setLiteProperty('customTheme', customTheme);

    return () => {
      removeLiteProperty('preview');
      removeLiteProperty('customTheme');
    };
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (!invoice) {
    return null;
  }

  return (
    <div className="px-6 py-10">
      {isCustomizationTemplate && (
        <div className="text-center py-1 bg-blue-900 text-white">
          <FormattedMessage id="INVOICE_CUSTOMIZATION_SAMPLE" />
        </div>
      )}
      <OnlineInvoice invoice={invoice} showPaymentDetails={true} readonly={true} />
    </div>
  );
};

export default PreviewPage;

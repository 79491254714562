import React, { PropsWithChildren, useState } from 'react';
import AppConfig from 'models/app-config';

export const DEFAULT_CONFIG: AppConfig = {
  env: '',
  bff: {
    url: ''
  }
};

export interface ConfigContextType {
  config: AppConfig;
  setConfig: (config: AppConfig) => void;
}

export const ConfigContext = React.createContext<ConfigContextType>({
  config: DEFAULT_CONFIG,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setConfig: (_: AppConfig) => {}
});

const ConfigContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [config, setConfig] = useState<AppConfig>(DEFAULT_CONFIG);

  return <ConfigContext.Provider value={{ config, setConfig }}>{children}</ConfigContext.Provider>;
};

export default ConfigContextProvider;

export default interface Attachment {
  referenceId: string;
  name: string;
  type: string;
  uploadDate?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseAttachment = (value: Record<string, any>): Attachment => ({
  referenceId: value?.referenceId ?? '',
  name: value?.name ?? '',
  type: value?.type ?? '',
  uploadDate: value?.uploadDate ? new Date(value?.uploadDate) : undefined
});

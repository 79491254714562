import { createHashRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { Invoice, NotFound, Preview } from 'components/pages';

const Root = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const router = createHashRouter([
  { path: '/preview', Component: Preview },
  { path: '/:onlineInvoiceId', Component: Invoice },
  { path: '*', Component: Root }
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;

import React, { MouseEventHandler, useCallback } from 'react';
import useConfig from 'hooks/useConfig';
import Attachment from 'models/attachment';
import { useIntl } from 'react-intl';
import { getFileIconComponent } from 'helpers/file-helper';
import InvoiceSection from '../invoice-section';
import classNames from 'classnames';
import Customization from 'models/customization';

export interface InvoiceAttachmentsProps {
  invoiceId: string;
  attachments: Attachment[];
  theme: Customization;
  className?: string;
  readonly?: boolean;
}

const InvoiceAttachments: React.FC<InvoiceAttachmentsProps> = ({
  invoiceId,
  attachments,
  theme,
  className,
  readonly
}) => {
  const config = useConfig();
  const { formatMessage } = useIntl();

  const getDownloadUrl = (attachment: Attachment, onlineInvoiceId: string) => {
    const FILE_NAME_KEY = 'fileName';

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set(FILE_NAME_KEY, attachment.name);

    const path = `${config?.bff.url || ''}/OnlineInvoices/${onlineInvoiceId}/attachments/${
      attachment.referenceId
    }`;

    return `${path}?${urlSearchParams.toString()}`;
  };

  const getFileIcon = (attachment: Attachment) => {
    const Component = getFileIconComponent(attachment.type);
    return <Component className="h-4 w-4" />;
  };

  const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (readonly) {
        e.preventDefault();
      }
    },
    [readonly]
  );

  return (
    <div className={className}>
      <InvoiceSection
        className={classNames(`text-${theme.color}`, `font-${theme.font}`)}
        label={formatMessage({ id: 'INVOICE_ATTACHMENTS' })}>
        <ul className="flex">
          {attachments.map((attachment) => (
            <li key={attachment.referenceId}>
              <a
                className="text-blue-600 truncate flex items-center pr-8 py-4"
                href={getDownloadUrl(attachment, invoiceId)}
                download={attachment.name}
                onClick={onClick}>
                <span className="pr-4">{getFileIcon(attachment)}</span>
                {attachment.name}
              </a>
            </li>
          ))}
        </ul>
      </InvoiceSection>
    </div>
  );
};

export default InvoiceAttachments;

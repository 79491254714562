import { useIntl } from 'react-intl';
import { toDecimal, getCurrencySymbol } from 'helpers/currency-helper';
import Money from 'models/money';

export const useMoneyCurrency = () => {
  const { formatNumber } = useIntl();

  return (money: Money) => {
    const value = toDecimal(money);
    return formatNumber(value, {
      style: 'currency',
      currency: money.currencyCode,
      // currencyDisplay: "narrowSymbol",
      // some browsers don't support `narrowSymbol`, just replace currency symbol to narrow here
      currencyDisplay: 'symbol'
    }).replace(/\w*\$/, getCurrencySymbol(money)); // temporarily replace $ symbol for UK and beta release
  };
};

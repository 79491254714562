import { ReactComponent as XeroGoIcon } from 'components/assets/icons/xero-go-app-icon.svg';
import classNames from 'classnames';

export interface InvoiceSentByProps {
  className?: string;
}

const InvoiceSentBy: React.FC<InvoiceSentByProps> = ({ className }) => {
  return (
    <div className={classNames('text-center', className)}>
      <p className="flex flex-row justify-center">
        <XeroGoIcon className="h-10 w-10" />
      </p>
      <p className="text-gray-600 text-sm mt-3">Invoice sent by Xero Go</p>
    </div>
  );
};

export default InvoiceSentBy;

/* eslint-disable @typescript-eslint/no-explicit-any */
import InvoiceTaxMode from 'enums/invoice-tax-mode';
import Attachment, { parseAttachment } from './attachment';
import Business from './business';
import Contact from './contact';
import Customization from './customization';
import InvoiceItem, { parseInvoiceItem } from './invoice-item';
import Money from './money';
import { StripePaymentConfig } from './stripe-payment-config';
import InvoiceStatus from 'enums/invoice-status';
import { isMoney, toMoney } from 'helpers/currency-helper';
import { getStatus, getTaxMode } from 'helpers/enum-helper';

export default interface Invoice {
  id: string;
  status: InvoiceStatus;
  totalAmount: Money;
  subTotal: Money;
  taxAmount: Money;
  taxMode: InvoiceTaxMode;
  isGstRegistered: boolean;
  number: string;
  contact: Contact;
  items: InvoiceItem[];
  note: string;
  datedDay?: Date;
  dueDay?: Date;
  sentDate?: Date;
  paidDate?: Date;
  viewDate?: Date;
  dueInDays: number;
  attachments: Attachment[];
  business: Business;
  customization: Customization;
  paymentModes?: string[];
  stripePaymentConfig?: StripePaymentConfig;
}

export const parseInvoice = (
  invoice: Partial<Invoice> | Record<string, any>,
  business?: Record<string, any>,
  customisation?: Record<string, any>
): Invoice => ({
  id: invoice?.id ?? '',
  contact: {
    name: invoice?.contact?.name ?? '',
    firstName: invoice?.contact?.firstName ?? '',
    lastName: invoice?.contact?.lastName ?? '',
    email: invoice?.contact?.email ?? '',
    physicalAddress: invoice?.contact?.physicalAddress ?? '',
    phone: {
      phoneAreaCode: invoice?.contact?.phone?.phoneAreaCode ?? '',
      phoneCountryCode: invoice?.contact?.phone?.phoneCountryCode ?? '',
      phoneNumber: invoice?.contact?.phone?.phoneNumber ?? ''
    },
    billingAddress: {
      addressLine1: invoice?.contact?.billingAddress?.addressLine1 ?? '',
      addressLine2: invoice?.contact?.billingAddress?.addressLine2 ?? '',
      addressLine3: invoice?.contact?.billingAddress?.addressLine3 ?? '',
      addressLine4: invoice?.contact?.billingAddress?.addressLine4 ?? '',
      city: invoice?.contact?.billingAddress?.city ?? '',
      region: invoice?.contact?.billingAddress?.region ?? '',
      postalCode: invoice?.contact?.billingAddress?.postalCode ?? '',
      country: invoice?.contact?.billingAddress?.country ?? ''
    }
  },
  business: {
    businessName: business?.businessName?.trim() ?? '',
    ABN: business?.ABN?.trim() ?? '',
    physicalAddress: business?.physicalAddress?.trim() ?? '',
    website: business?.website?.trim() ?? '',
    contactEmail: business?.contactEmail?.trim() ?? '',
    phoneNumber: business?.phoneNumber?.trim() ?? '',
    isGstRegistered: business?.isGstRegistered ?? false
  },
  customization: {
    logo: customisation?.logo ?? '',
    color: customisation?.color ?? '',
    font: customisation?.font ?? '',
    paymentInstructions: customisation?.paymentInstructions ?? ''
  },
  number: String(invoice?.number) ?? '',
  dueDay: invoice?.dueDay ? new Date(invoice.dueDay) : undefined,
  datedDay: invoice?.datedDay ? new Date(invoice.datedDay) : undefined,
  dueInDays: invoice?.dueInDays ?? 0,
  paidDate: invoice?.paidDate ? new Date(invoice.paidDate) : undefined,
  items: Array.isArray(invoice?.items) ? invoice.items.map((item) => parseInvoiceItem(item)) : [],
  status: getStatus(invoice?.status),
  note: invoice?.note ?? '',
  attachments: Array.isArray(invoice?.attachments)
    ? invoice?.attachments?.map((att) => parseAttachment(att))
    : [],
  taxMode: getTaxMode(invoice?.taxMode),
  subTotal: isMoney(invoice?.subTotal) ? (invoice.subTotal as Money) : toMoney(0, 'GBP'),
  taxAmount: isMoney(invoice?.taxAmount) ? (invoice.taxAmount as Money) : toMoney(0, 'GBP'),
  totalAmount: isMoney(invoice?.taxAmount) ? (invoice.totalAmount as Money) : toMoney(0, 'GBP'),
  isGstRegistered: invoice?.isGstRegistered ?? false,
  stripePaymentConfig: invoice?.stripePaymentConfig ?? undefined
});

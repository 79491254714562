import React from 'react';
import Money from 'models/money';
import { FormattedDate, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { useMoneyCurrency } from 'hooks/useMoneyCurrency';
import classNames from 'classnames';

export interface StripePanelHeaderProps {
  dueDate?: Date;
  dueInDays: number;
  totalAmount: Money;
}

const StripePanelHeader: React.FC<StripePanelHeaderProps> = ({
  dueDate,
  dueInDays,
  totalAmount
}) => {
  const formatCurrency = useMoneyCurrency();
  const isOverdue = !!dueInDays && dueInDays < 0;

  return (
    <div>
      <h2 className="text-2xl font-bold my-5">
        <FormattedMessage id="STRIPE_PANEL_HEADER_PAY_INVOICE" />
      </h2>
      <div>
        <span className="font-bold text-lg">
          <FormattedMessage id="STRIPE_PANEL_HEADER_AMOUNT_DUE" />
        </span>
        <span className="pl-2.5 text-lg">{formatCurrency(totalAmount)}</span>
      </div>
      {dueDate && (
        <div>
          <span className="font-bold text-lg">
            <FormattedMessage id="STRIPE_PANEL_HEADER_DUE_DATE" />
          </span>
          <span className={classNames('pl-2.5 text-lg', { 'text-status-overdue': isOverdue })}>
            <FormattedDate day="numeric" month="short" value={dueDate} year="numeric" />
            {isOverdue && (
              <span className="ml-1">
                (<FormattedRelativeTime unit="day" value={dueInDays} />)
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default StripePanelHeader;

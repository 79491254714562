import Invoice from 'models/invoice';
import InvoiceTaxMode from 'enums/invoice-tax-mode';
import InvoiceTaxType from 'enums/invoice-tax-type';
import PaymentMode from 'enums/payment-mode';
import InvoiceStatus from 'enums/invoice-status';

export const emptyInvoice: Invoice = {
  attachments: [],
  business: {},
  contact: {
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      city: '',
      country: '',
      postalCode: '',
      region: ''
    },
    email: '',
    firstName: '',
    lastName: '',
    name: '',
    phone: { phoneAreaCode: '', phoneCountryCode: '', phoneNumber: '' },
    physicalAddress: ''
  },
  customization: { color: '', font: '', logo: '', paymentInstructions: '' },
  datedDay: undefined,
  dueDay: undefined,
  dueInDays: 0,
  id: '12345678-1111-2222-3333-123456789ABC',
  isGstRegistered: false,
  items: [],
  note: '',
  number: '1',
  paidDate: undefined,
  paymentModes: [],
  sentDate: undefined,
  status: InvoiceStatus.Unpaid,
  stripePaymentConfig: { paymentIntentClientSecret: '', publishedKey: '', stripeAccountId: '' },
  subTotal: { currencyCode: '', nanos: 0, units: 0 },
  taxAmount: { currencyCode: '', nanos: 0, units: 0 },
  taxMode: InvoiceTaxMode.NoTax,
  totalAmount: { currencyCode: '', nanos: 0, units: 0 },
  viewDate: undefined
};

export const paidInvoice: Invoice = {
  attachments: [
    {
      name: 'invoice.docx',
      referenceId: 'FE6068AB-A48F-4262-A79F-83BDD6C7C5A6',
      type: 'docx',
      uploadDate: new Date('2023-10-20')
    },
    {
      name: 'invoice.jpeg',
      referenceId: '1C56B377-866A-4A08-9A1C-0B49ACA7D747',
      type: 'jpeg',
      uploadDate: new Date('2022-01-05')
    }
  ],
  business: {
    businessName: 'Nightwatch',
    businessNumber: '778899',
    physicalAddress: 'Business street address Suburb, State, Post code',
    website: 'www.mywebsite.com',
    contactEmail: 'john.snow@stark.com',
    phoneNumber: '(123) 456789'
  },
  contact: {
    name: 'John Snow',
    firstName: 'FirstName',
    lastName: 'LastName',
    email: 'test@xero.com',
    phone: {
      phoneAreaCode: '',
      phoneCountryCode: '+61',
      phoneNumber: '456 789'
    },
    physicalAddress: '123 Smith Street, City, Postcode, Country',
    billingAddress: {
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      addressLine3: 'addressLine3',
      addressLine4: 'addressLine4',
      city: 'Melbourne',
      region: 'SouthBank',
      postalCode: 'PostCode',
      country: 'Country'
    }
  },
  customization: {
    color: '#000A1E',
    font: 'roboto',
    logo: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAYFBMVEUatN")',
    paymentInstructions: 'Here are some payment instructions'
  },
  datedDay: new Date('2023-10-12'),
  dueDay: new Date('2099-12-31'),
  dueInDays: 0,
  id: '12345678-1111-2222-3333-123456789ABC',
  isGstRegistered: false,
  items: [
    {
      name: 'Apple',
      description: 'red',
      quantity: 200,
      price: {
        currencyCode: 'GBP',
        units: 2,
        nanos: 0
      },
      amount: {
        currencyCode: 'GBP',
        units: 400,
        nanos: 0
      },
      taxRate: 10,
      taxType: InvoiceTaxType.GSTOnIncome,
      chartOfAccount: 'Sales'
    }
  ],
  note: 'Some notes',
  number: '1',
  paidDate: new Date('2023-10-15'),
  paymentModes: [PaymentMode.PerInstruction],
  sentDate: new Date('2023-10-12'),
  status: InvoiceStatus.Paid,
  stripePaymentConfig: {
    paymentIntentClientSecret: '',
    publishedKey: '',
    stripeAccountId: ''
  },
  subTotal: { currencyCode: 'GBP', nanos: 0, units: 0 },
  taxAmount: { currencyCode: 'GBP', nanos: 0, units: 0 },
  taxMode: InvoiceTaxMode.NoTax,
  totalAmount: { currencyCode: 'GBP', nanos: 0, units: 400 },
  viewDate: undefined
};

export const unpaidInvoice: Invoice = Object.assign({}, paidInvoice, {
  status: InvoiceStatus.Unpaid,
  dueInDays: 100000,
  paidDate: undefined
});

export const overdueInvoice: Invoice = Object.assign({}, unpaidInvoice, {
  dueInDays: -1
});

export const unpaidStripeInvoice: Invoice = Object.assign({}, unpaidInvoice, {
  stripePaymentConfig: {
    paymentIntentClientSecret: 'INSERT VALID CLIENT SECRET',
    publishedKey: 'INSERT VALID PUBLISHED KEY',
    stripeAccountId: 'INSERT VALID STRIPE ACCOUNT ID'
  },
  paymentModes: [PaymentMode.Stripe]
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as TickIcon } from 'components/assets/icons/tick-icon.svg';
const StripePaymentSuccess: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <TickIcon />
      <div className="pt-4">
        <FormattedMessage id="STRIPE_PAYMENT_SUCCESS_MESSAGE" />
      </div>
    </div>
  );
};

export default StripePaymentSuccess;

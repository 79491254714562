import { PropsWithChildren } from 'react';

export interface InvoiceSectionProps {
  label: string;
  className?: string;
}

const InvoiceSection: React.FC<PropsWithChildren<InvoiceSectionProps>> = ({
  label,
  children,
  className
}) => {
  return (
    <div className={className}>
      <h3 className="font-semibold whitespace-pre mb-1 md:mb-5">{label}</h3>
      <div className="break-all hyphens-auto whitespace-pre-line text-gray-600">{children}</div>
    </div>
  );
};

export default InvoiceSection;

import PreviewMode from 'enums/preview-mode';

export type ViewPort = [width: number | 'device-width', scale: number];

export const changeViewPort = (viewPort: ViewPort): void => {
  const [width, scale] = viewPort;
  document
    .querySelector("meta[name='viewport']")
    ?.setAttribute('content', `width=${width}, initial-scale=${scale}`);
};

export const calculateScale = (outerWith: number): ViewPort => {
  const defaultWidth = 850;

  if (outerWith >= defaultWidth) {
    return [outerWith, 1];
  }

  const targetScale = Math.round((outerWith * 100) / defaultWidth) / 100;

  return [defaultWidth, targetScale];
};

export const switchPreviewMode = (mode: PreviewMode) => {
  if (mode === PreviewMode.Mobile) {
    changeViewPort(['device-width', 1]);
    return;
  }

  changeViewPort(calculateScale(window.outerWidth));
};

import { switchPreviewMode } from 'helpers/window-helper';
import { LiteConfig } from 'lite';

export const registerGlobalLite = () => {
  window.lite = {
    switchPreviewMode
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLiteProperty = (name: keyof LiteConfig, value: any) => {
  window.lite[name] = value;
};

export const removeLiteProperty = (name: keyof LiteConfig) => {
  delete window.lite[name];
};

import { ThreeDots } from 'react-loader-spinner';

const PageLoader: React.FC = () => {
  return (
    <div className="h-full flex items-center justify-center">
      <ThreeDots
        height="50"
        width="50"
        radius="9"
        color="#404756"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );
};

export default PageLoader;

import InvoiceStatus from 'enums/invoice-status';
import InvoiceTaxMode from 'enums/invoice-tax-mode';
import InvoiceTaxType from 'enums/invoice-tax-type';
import Region from 'enums/region';

export const getTaxMode = (taxMode: string, region: Region = Region.GB): InvoiceTaxMode => {
  if (region === Region.GB) {
    return InvoiceTaxMode.NoTax;
  }

  switch (taxMode?.toLowerCase()) {
    case InvoiceTaxMode.Exclusive.toLowerCase():
      return InvoiceTaxMode.Exclusive;
    case InvoiceTaxMode.Inclusive.toLowerCase():
      return InvoiceTaxMode.Inclusive;
    default:
      return InvoiceTaxMode.NoTax;
  }
};

export const getTaxType = (taxType: string, region: Region = Region.GB): InvoiceTaxType => {
  if (region === Region.GB) {
    return InvoiceTaxType.NoVAT;
  }

  switch (taxType?.toLowerCase()) {
    case InvoiceTaxType.GSTFree.toLowerCase():
      return InvoiceTaxType.GSTFree;
    case InvoiceTaxType.GSTOnIncome.toLowerCase():
      return InvoiceTaxType.GSTOnIncome;
    default:
      return InvoiceTaxType.BASExcluded;
  }
};

export const getStatus = (status: string): InvoiceStatus => {
  switch (status.toLowerCase()) {
    case InvoiceStatus.Draft.toLowerCase():
      return InvoiceStatus.Draft;
    case InvoiceStatus.Paid.toLowerCase():
      return InvoiceStatus.Paid;
    case InvoiceStatus.Unpaid.toLowerCase():
      return InvoiceStatus.Unpaid;
    case InvoiceStatus.Viewed.toLowerCase():
      return InvoiceStatus.Viewed;
    default:
      return InvoiceStatus.Unpaid;
  }
};
